<template>
	<h1><i class="bi bi-slash-circle"></i> 権限エラー</h1>
    <p class="mt-3">この画面を操作する権限がありません。操作する必要がある場合は、管理者に連絡してください。</p>
</template>

<script>
export default {
    name: 'PageError404',
}
</script>

<style scoped>

</style>
